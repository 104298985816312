// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Hero from 'components/Contentful/Hero'
import HowTo from 'components/Contentful/HowTo'
import IntroAndPicture from 'components/Contentful/IntroAndPicture'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Erfahrungsbericht Familie Konzett"
        meta={[
          { name: 'description', content: 'Die Familie Konzett aus Binz ZH darf ihren neu entstandenen Raum in vollen Zügen geniessen. Gleich reinschauen!' },
          { name: 'keywords', content: 'Erfahrungsbericht Ersatz Ölheizung durch Wärmepumpe' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='2tl3lkMOPpmJ5lP70ZajR-2TBcJVyZ9AkBOdj4VMSveV'
          id='familie-konzett-binz-zh'
        >
          <Hero
            {...{
    "type": "hero",
    "id": "2TBcJVyZ9AkBOdj4VMSveV",
    "updatedAt": "2021-08-10T07:35:48.627Z",
    "title": "FAMILIE KONZETT, BINZ ZH",
    "size": "Groß",
    "teaser": "Nachdem die alte Ölheizung der Familie Konzett durch eine Wärmepumpe ersetzt worden ist, entstand im Keller ein 12m2 grosser “spiritueller” Rückzugsort. 30 Jahre lang war es ein düsterer Raum ohne Tageslicht ausgefüllt von einem 8200 L Öltank. Nun erstrahlt das Zimmer dank einem neuen Bodenbelag, einem nachträglich eingebauten Fenster und einer frischen Wandfarbe im  neuen Glanz.",
    "image": {
        "description": "Titelbild Referenz-Story Familie Konzett",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/1nSHMoIJfqFyiNYP2Hgp3p/091d04db7ce06316816c9a697c82c749/W__rmepumpe_Familie_Konzett_-_HERO.png"
    },
    "links": [
        {
            "type": "link",
            "id": "2HoGCnl720mMqmQ108H1F7",
            "updatedAt": "2023-07-25T06:25:06.308Z",
            "label": "Zur Referenz-Übersicht",
            "url": "https://www.heizungsmacher.ch/referenzen/"
        }
    ],
    "anchorName": "familie-konzett-binz-zh"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='2tl3lkMOPpmJ5lP70ZajR-5BPE9EnfDMuSO6GchqCjII'
          id=''
        >
          <HowTo
            {...{
    "type": "howTo",
    "id": "5BPE9EnfDMuSO6GchqCjII",
    "updatedAt": "2021-08-10T08:43:44.214Z",
    "title": "«Die ganze Planung lief wie am Schnürchen.»",
    "steps": [
        {
            "type": "howToStep",
            "id": "5KmPRmA7NkzuuIeqM8ZGC6",
            "updatedAt": "2021-08-10T09:00:07.387Z",
            "title": "Überzeugt hat uns die Komplett-Lösung",
            "description": "<p>30 Jahre lang war die alte <a href=\"https://www.heizungsmacher.ch/wissen/oelheizung-ersetzen-informationen-foerderungen-regelungen/\">Ölheizung</a> der Familie Konzett im Einsatz. Sie wussten jedoch, dass die Anlage nicht ewig halten würde – und dementsprechend machten sie sich <strong>frühzeitig auf die Suche nach einer geeigneten</strong> <a href=\"https://www.heizungsmacher.ch/heizungsersatz/\">Heizungsersatz-Lösung</a>.</p>\n<p>Familie Konzett berichtet: «Erstmal durchsuchten wir das Internet nach <a href=\"https://www.heizungsmacher.ch/wissen/waermepumpen-hersteller-im-vergleich/\">Wärmepumpen Herstellern</a> und achteten darauf, dass die Firmen eine fundierte Erfahrung aufweisen. Zwei Aspekte wurden bei den Offerten berücksichtigt – die Preise und der Auftritt.»</p>\n",
            "image": {
                "description": "Fotos vor dem Umbau",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/52VhVISbZfJeGjZSeLGIOG/c0d7eecc0dbd301c4e2a8027e1d41510/Fotos-vor-der-Installation-der-W__rmepumpe---Familie-Konzett.png"
            }
        },
        {
            "type": "howToStep",
            "id": "CqCzNYHNYxZziKg4nk9Ph",
            "updatedAt": "2022-06-16T07:57:12.714Z",
            "title": "«Wir wollten keine 5 Geräte im Raum stehen haben.»",
            "description": "<p>Krystyna Konzett schildert: «Unsere Suche reduzierten wir schliesslich auf Hersteller, welche <a href=\"https://www.heizungsmacher.ch/waermepumpen/kompakt-speicher/\">Kompakt-Lösungen</a> anboten. Wir wollten keine 5 Geräte im Raum stehen haben.» Sie erklärt weiter, dass sie auch darauf geachtet hätten, <strong>eine passende Anlage</strong>, in ihrem Fall den Kompaktspeicher VVM 500 von <a href=\"https://www.heizungsmacher.ch/waermepumpen/kompakt-speicher/\">NIBE</a> auszuwählen, <strong>welche nicht viel Platz beansprucht</strong>.</p>\n<p>Der <a href=\"https://www.heizungsmacher.ch/ueber-uns/\">Heizungs-Spezialist</a> von der Heizungsmacher AG konnte dank der markenunabhängigen Produktauswahl auf die einzelnen Bedürfnisse eingehen und entschied sich zusammen mit Familie Konzett für eine platzsparende Anlage von NIBE, bei der die Anschlüsse nach oben ausgerichtet waren und das Gerät somit direkt an die Wand gestellt werden konnte.</p>\n<p>Auch <strong>die ultimative Komplett-Lösung</strong>, bei der der administrative Aufwand wie das Beantragen der <a href=\"https://www.heizungsmacher.ch/foerdergelder/\">Fördergelder</a> und Erstellen der Baugesuche übernommen wird, <strong>hätte die Familie Konzett dazu veranlasst, das Projekt schliesslich von der Heizungsmacher AG ausführen zu lassen</strong>.</p>\n",
            "image": {
                "description": "Kompaktspeicher VVM 500 von NIBE der Familie Konzett",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/o3e1n5gvgkwEMavSNUihp/96044da38ee627db98d51028994db5e8/Kompaktspeicher-NIBE-VVM-500-von-Familie-Konzett.jpg"
            }
        },
        {
            "type": "howToStep",
            "id": "70b6bahhyIOu3d1WpB2vgj",
            "updatedAt": "2021-08-10T09:02:43.399Z",
            "title": "Trotz Hindernissen wurde der Zeitplan exakt eingehalten",
            "description": "<p>Krystyna Konzett: «<strong>Die Arbeiten wurden zuverlässig und sauber verrichtet und der Zeitplan gezielt eingehalten</strong>.» «Da ein weiterer Heizungsmacher-Kunde wegen eines Notfalles die Hilfe der Mitarbeiter benötigte, mussten die Arbeiten für eine kurze Zeit stillgelegt werden. Zu unserer Überraschung haben sie es trotzdem geschafft, den Umbau exakt nach Plan abzuschliessen», fügte sie hinzu. <strong>Familie Konzett hat die verlässliche und transparente Kommunikation sehr geschätzt</strong>.</p>\n",
            "image": {
                "description": "der alte Tankraum von Familie Konzett",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/6nkrCKtiW3RU4ZCWMGg502/d222347545a886b8090a6f3d9b420642/alter-Tankraum-von-Familie-Konzett.png"
            }
        },
        {
            "type": "howToStep",
            "id": "tTTqBJA0uWGfZ1lF8Xv4F",
            "updatedAt": "2021-08-10T09:04:36.981Z",
            "title": "«Wir fühlten uns ernst genommen.»",
            "description": "<p>Bis heute läuft die Anlage einwandfrei. Nachdem die ganze Bauphase abgeschlossen war, wurde die Wärmepumpe von <a href=\"https://www.heizungsmacher.ch/waermepumpen/luft-wasser-aussenaufstellung/nibe-w%C3%A4rmepumpe-f2120-20/\">NIBE F2120-20</a> über die Einstellungen des <a href=\"https://www.heizungsmacher.ch/monitoring/\">Online-Monitorings</a> angepasst und exakt auf die Bedürfnisse der Familie Konzett abgestimmt. «<strong>Wir fühlten uns von der ersten Beratung an gut aufgehoben und sind froh, den <a href=\"https://www.heizungsmacher.ch/heizungsersatz/\">Heizungsersatz</a> den Heizungsmachern überlassen zu haben</strong>.»</p>\n",
            "image": {
                "description": "der neue Rückzugsort von Familie Konzett",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/7n25rlRAn5qI1FztLTUBV4/67b96061e58bec7842f395e347facc70/der-neue-Tankraum-von-Familie-Konzett.png"
            }
        }
    ]
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='2tl3lkMOPpmJ5lP70ZajR-5jl6yjDwGax05p7DpzlEBw'
          id='wuenschen-sie-eine-beratung'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "5jl6yjDwGax05p7DpzlEBw",
    "updatedAt": "2023-08-30T09:41:16.098Z",
    "title": "Wünschen Sie eine Beratung?",
    "anchorName": "wuenschen-sie-eine-beratung",
    "text": "<p>Sie interessieren sich für eine Wärmepumpe und sind noch nicht sicher, ob dies die richtige Wahl ist? Besuchen Sie unseren kostenlosen Heizungs-Check oder lassen Sie sich von unseren <a href=\"https://www.heizungsmacher.ch/ueber-uns/\" title=\"ueber-uns\">Heizexperten</a> beraten.</p>\n",
    "link": {
        "type": "link",
        "id": "7p7eaG5GRI8A1fElOkNxIb",
        "updatedAt": "2022-06-28T08:23:33.929Z",
        "label": "Online Heizungs-Check",
        "url": "https://www.heizungsmacher.ch/konfigurator"
    }
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
